import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Title from "@components/Title";

export default () => (
  <>
    <Helmet title="Terms & Conditions" />
    <div className="pb-8">
      <Title as="h1" className="mb-4">
        Terms & Conditions
      </Title>

      <Title as="h3" className="pb-2">
        Definition of our terminology as used within this document:
      </Title>
      <StyledList>
        <StyledLine>
          A 'Project' is any work undertaken or service provided by Edge Labs
          LTD for the client on their request and as described in our
          confirmation order email to that Client.
        </StyledLine>
        <StyledLine>
          'You' or 'Client,' is a person, persons, business or organisation
          using any of the services provided by Edge Labs LTD.
        </StyledLine>
        <StyledLine>'We' or 'us', means Edge Labs LTD</StyledLine>
        <StyledLine>
          'Live Mode' means the date the website is available on the client's
          chosen domain.
        </StyledLine>
        <StyledLine>
          'Domain' is the website address as specified by the client.
        </StyledLine>
        <StyledLine>
          'Open Source Software' is software made freely available to anyone
          under the GNU General Public License (GPL).
        </StyledLine>
        <StyledLine>
          'Hosting' is a yearly cost to keep a client’s website activated
          online.
        </StyledLine>
        <StyledLine>
          'Content' is both text and images that the client requires on the
          website.
        </StyledLine>
        <StyledLine>
          'mb' stands for megabytes and is a measure of storage space.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        General
      </Title>
      <StyledList>
        <StyledLine>
          The contract between Edge Labs LTD and the client will be on these
          conditions, to the exclusion of all other terms and conditions. Any
          variations to these conditions shall have no effect unless agreed in
          writing.
        </StyledLine>
        <StyledLine>
          The works to be carried out shall be as set out in the Edge Labs LTD
          invoice which can be found attached to the confirmation order email.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Legalities, Copyright and Intellectual Property
      </Title>
      <StyledList>
        <StyledLine>
          Edge Labs LTD shall expect the client to carry out sufficient research
          before proceeding with a website. This will include checking that the
          website/idea/business will operate legally. It is important that the
          website is not in any way illegal. Please ensure that any images or
          content you supply or use on your website, is legally available for
          you to use. We cannot be held responsible for the illegal use of
          images, content or format of material supplied by the client. Edge
          Labs LTD reserve the right to remove any website from its servers if
          it has reason to believe that it is operating illegally.
        </StyledLine>
        <StyledLine>
          Where images used on the website have been purchased by Edge Labs LTD
          on behalf of the client, these images are restricted by license for
          use on the website only and are limited to 15 images per project. Edge
          Labs LTD are not liable for misuse of these images by the client or
          any other person's copying, altering or distributing the images to
          individuals or other organisations.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD makes no claims that the contents of this website may be
          lawfully viewed or downloaded outside England and Wales. Access to
          this website may not be legal by certain persons or in certain
          countries. If this website is accessed from outside of the United
          Kingdom, it is done at own risk and the visitor is responsible for
          compliance with the relevant laws of the visitor's jurisdiction. The
          terms and conditions of this website are governed by the laws of
          England and Wales. Jurisdiction for any claims arising in respect of
          this website's content shall lie exclusively with the courts of
          England. If any provision of these terms and conditions is found to be
          invalid by any Court having competent jurisdiction, the invalidity of
          such provision shall not affect the validity of the remaining
          provisions of these terms and conditions, which shall remain in full
          force and effect.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD cannot be held responsible for anything adversely
          affecting the Client's business operation, sales, or profitability
          that might be claimed is a result of a service offered by Edge Labs
          LTD.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Paying for your project
      </Title>
      <StyledList>
        <StyledLine>
          We charge for the design and development of websites at various
          stages. Before we start work, a 50% deposit of the total sum due is
          payable (the deposit). When the websites design is approved by you and
          the home page is completed, a further 25% is due (design payment). The
          final 25% is payable when the website is complete and ready to
          'go-live' (final payment) Your website will only be switched to 'live'
          mode when it has been paid for, in full. The initial deposit payment
          is non-refundable after Edge Labs LTD has committed resource or
          completed research and/or design work on the client's behalf.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD will charge 1.9% fee for all payments made using a
          credit or debit card. This fee is charged due to the costs incurred by
          Edge Labs LTD for receiving such payments by our service provider. If
          this is not acceptable to the client, we also accept BACS and cheques
          as alternative payment methods which will incur no payment fees. If
          paying by cheque, please understand that we will have to allow the
          cheque to clear the banking system before starting or continuing with
          your project.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD own all design and code of the website until final
          payment has been received in full. On receipt of final payment full
          ownership of the site files are handed to the client, this includes
          all design, branding and code.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Communication
      </Title>
      <StyledList>
        <StyledLine>
          Email is our primary method of contact with regard to all
          communication for your websites design and development. We have
          website designers and developers working for us in various parts of
          the world, as well as here in the UK. Email is obviously the most
          efficient and cost-effective form of contact. If you have any problems
          with the design or development of your website that your developer is
          unable to resolve by email, you can contact our telephone support
          service during UK office hours +44 (0) 203 918 8597 to talk to our
          UK-based support team.
        </StyledLine>
        <StyledLine>
          It is the client's responsibility to inform us by email of any change
          in email address so we always have valid contact details. We cannot be
          held liable in any way for problems relating to communication issues
          if we are not supplied with a valid email address. Some of our systems
          rely on this email to inform you of various important information. We
          also advise that clients check junk and spam folders regularly.
        </StyledLine>
        <StyledLine>
          It is important for the client to keep in contact with Edge Labs LTD
          throughout the entire project. If a client does not make contact for
          more than 2 weeks, we will make up to 5 attempts to contact the client
          by email using the email address for the client held on file. If we do
          not receive a response to these attempts of contact the project may be
          terminated, and the deposit will not be refunded. If the client wishes
          to postpone the project for a period of longer than 2 weeks, please
          contact us. Otherwise the project may be archived. We reserve the
          right to levy a £100 plus VAT administration fee if the client returns
          after an unapproved postponement where the time since last contact is
          less than 6 months and wishes to continue work on their project.
        </StyledLine>
        <StyledLine>
          If a client returns from an unapproved postponement and the last
          contact is more than 6 months, we shall need to provide a new
          quotation for the project and your original deposit will be
          non-refundable or transferable. Any design or development work
          completed previously may no longer be available.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Website Design and Development
      </Title>
      <StyledList>
        <StyledLine>
          Design is subjective, and for this reason we offer 'unlimited design
          revisions'. This means that we will continue to work with you on your
          design concept until you are happy with our proposal. The unlimited
          design revision period ends when the client accepts the design
          proposal and home page draft. Design changes beyond this point are
          subject to additional charges.
        </StyledLine>
        <StyledLine>
          Design and layout changes to the internal pages of the website are
          subject to restrictions within developments.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD will provide the client with an expected completion time
          frame for the project (live on the internet) if requested. Edge Labs
          LTD will provide a client access to its 'Project Cycle' documentation
          to ensure a client is fully aware of the responsibilities they have to
          ensure a successful and timely development. In order to provide a
          completion date a final list of changes is needed from the client.
        </StyledLine>
        <StyledLine>
          Open Source software (OSS) is publically available software that may
          be adapted or modified to suit particular applications. OSS may be
          used to help develop elements of the functionality of the client's
          website to speed development and add functionality. Where Open Source
          software is used, the ownership of the site coding and any
          customisations made by the developer is still passed to the client
          upon final payment.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Hosting and Search Engine Optimisation
      </Title>
      <StyledList>
        <StyledLine>
          Edge Labs LTD will host the website if the client requires us to do so
          and on receipt of full payment of our hosting fees. In doing so, Edge
          Labs LTD will provide a reliable and professional service to the
          client at all times, excluding unavoidable technical failures. All
          standard hosting offered by Edge Labs LTD is limited to 1Gb of website
          space unless otherwise stated in a separate contract.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD hosting is annually renewable. The date of renewal will
          be 12 calendar months from the date the website was made live. Edge
          Labs LTD will contact the client 6 weeks before the renewal date and
          hosting services are not obligated.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD will provide the client with an expected completion time
          frame for the project (live on the internet) if requested. Edge Labs
          LTD will provide a client access to its 'Project Cycle' documentation
          to ensure a client is fully aware of the responsibilities they have to
          ensure a successful and timely development. In order to provide a
          completion date a final list of changes is needed from the client.
        </StyledLine>
        <StyledLine>
          The hosting renewal charge must be received within 10 days of the
          hosting expiry date. We reserve the right to deactivate any website
          when the hosting has expired and the client has not paid the renewal
          charge. We reserve the right to charge an administration fee of £50
          plus VAT for reactivating the website/hosting.
        </StyledLine>
        <StyledLine>
          If the client does not use Edge Labs LTD hosting services, then the
          management and hosting of the domain name are the full responsibility
          of the client.
        </StyledLine>
        <StyledLine>
          If the client does not wish to renew the hosting the site files and
          associated data can be made available to the client fee free on
          request and the domain name can be transferred to the client's
          registrar account. Should the client instruct us to upload and
          configure the site and domain name to a third party server this work
          will be subject to charge.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD reserve the right to delete the website from our servers
          3 months after the date your hosting expired if you choose to not
          renew the hosting.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD offer free SSL with sites we host and manage DNS for. If
          you wish to manage DNS yourself we cannot provide support for SSL.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD do not take any responsibility for a client's website
          rank on search engines. This also includes any potential website
          downtime that can occur. When updating, changing, creating and hosting
          a client's website, there could be a change in the websites search
          engine ranking.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Domain Names and Links
      </Title>
      <StyledList>
        <StyledLine>
          If required, a domain name will be registered by Edge Labs LTD on
          behalf of the client once we have received payment in full. These are
          limited to .co.uk and .com, any additional domain names can be
          purchased for and additional charge. Although the domain names are
          registered to us, the client is the legal owner of the domain and if
          they request to have details changed or the domain transferred
          elsewhere, we will do this within a reasonable timeframe.
        </StyledLine>
        <StyledLine>
          Domain names require yearly renewal and there is an associated fee for
          this. Each client who hosts their website with Edge Labs LTD receives
          1 free domain name renewal every 12 months as part of their hosting
          providing they pay their hosting renewal fee. This is limited to
          .co.uk and .com domains, any other Top Level Domains will carry
          separate charges.
        </StyledLine>
        <StyledLine>
          Any domain name renewal is the responsibility of the client. Edge Labs
          LTD will attempt to contact the client by email up to 2 weeks in
          advance of a domain renewal being due. If the client doesn’t pay to
          renew their domain name, the domain name shall not be renewed. We
          advise that you contact us 50 weeks after you purchase or renew a
          domain if you wish to renew it for another year.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD provides links or references to our client's websites
          solely for the convenience of prospective customers and intends that
          the links it provides be current and accurate, but does not guarantee
          or warrant that such links will point to the intended client site at
          all times.
        </StyledLine>
        <StyledLine>
          We place a small text link on the footer of our client's website that
          simply states the website was designed by Edge Labs LTD and links to
          our company website. This link can be removed, if required.
        </StyledLine>
      </StyledList>

      <Title as="h3" className="pb-2">
        Support
      </Title>
      <StyledList>
        <StyledLine>
          While a website is hosted with Edge Labs LTD, we shall be available
          between the hours of 9.00am and 5.30pm Monday – Friday. This excludes
          all bank holidays. Within a reasonable time period we will look to fix
          any issues with the website which are not due to a customer’s doing.
        </StyledLine>
        <StyledLine>
          Edge Labs LTD email accounts are available on any device that supports
          email. These include the Apple iPhone and Samsung Galaxy as well as
          many others. Edge Labs LTD will provide instructions on how to setup
          email on these devices via our support area on our website. We are not
          able to provide email or phone support for these devices.
        </StyledLine>
        <StyledLine>
          (*) Edge Labs LTD will not tolerate any form of harassment against
          it's employees from customers or third parties and we reserve the
          right to cancel a contract without refund in the event of unreasonable
          or inappropriate conduct. This includes threatening behaviour and
          abuse directed towards our employees thereafter any further
          communication must be via postal mail only.
        </StyledLine>
      </StyledList>
    </div>
  </>
);

/**
 * Utility class not used here to prevent
 * repeating className props
 */
const StyledList = styled.ul`
  &&& {
    padding-bottom: 1rem;
  }
`;

const StyledLine = styled.li`
  &&& {
    list-style: circle;
    margin: 0 0 0.5rem 1.2rem;
  }
`;
